import { Link, useNavigate } from "react-router-dom";

import {
  faLink,
  faRightFromBracket,
  faTimeline,
  faGauge,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Brand from "../shared/Brand";
import { getAuth, signOut } from "firebase/auth";
import useToken from "../../security/useToken";
import firebaseConfig from "@serie3/mymediaplanner/Config/Firebase/firebase";
import { initializeApp } from "firebase/app";

const Sidebar = () => {
  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  const { token, setToken } = useToken();
  const navigate = useNavigate();

  return (

  <div className="vertical-menu">
    <div
      className="navbar-brand-box"
      style={{ height: "70px", paddingTop: "20px" }}
    >
      <Brand />
    </div>
    <div className="h-100">
      <div className="sidebar-menu-scroll">
        <div className="simplebar-mask" style={{ marginTop: "70px" }}>
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled">
              <li className="menu-title">Navi</li>
              <li>
                <Link to="/">
                  <FontAwesomeIcon icon={faGauge} />
                  <span className="menu-item">Dashboard</span>
                </Link>
              </li>
              <li>
                <Link to="/timeline">
                  <FontAwesomeIcon icon={faTimeline} />
                  <span className="menu-item">Timeline</span>
                </Link>
              </li>
              <li>
                <Link to="/callbacks/linkedin">
                  <FontAwesomeIcon icon={faLink} />
                  <span className="menu-item">Connections</span>
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  onClick={() => {
                    setToken(null, null);
                    window.location.reload();
                    signOut(auth);
                    navigate("/");
                  }}
                >
                  <FontAwesomeIcon icon={faRightFromBracket} />
                  <span className="menu-item">Logout</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>);
};


export default Sidebar;