const SocialEndpoints = {
  overview: "/social/connected",
  posts: {
    index: "social/post/",
    new: "social/post/new",
    edit: "social/post/edit",
    delete: "social/post/delete/",
    show: "social/post/show",
    publish: {
      linkedin: "social/linkedin/publish/",
    },
    postImage: "social/post/postimage/",
    dataField: "posts",
  },
};

export default SocialEndpoints;
