import { useEffect, useState } from "react";
import CompleteDropdown from "../../shared/Dropdown/CompleteDropdown";
import crudApi from "@serie3/common/api/crudApi";
import SocialEndpoints from "@serie3/mymediaplanner/Modules/Social/endpoints";
import { Button, Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";

const Timelineitem = ({ post, editAction, deleteAction, publish }) => {
  const [postPicture, setPostPicture] = useState(null);
  const timelineContainerStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "10px", // Adjust the gap between timeline items as needed
  };

  const timelineItemStyle = {
    display: "flex",
    justifyContent: "space-between", // Ensure content is spaced correctly
    alignItems: "center",
  };

  const timelineDateStyle = {
    textAlign: "left",
    paddingRight: "10px", // Adjust the padding as needed
  };

  const timelineContentStyle = {
    textAlign: "right", // Ensure text is aligned to the left within the content area
    flex: 1,
  };

  const image = {
    borderRadius: "4px",
    height: "40px",
    width: "auto",
  };

  const { t } = useTranslation();

  useEffect(() => {
    const getImage = async () => {
      const response = await crudApi.getBinary(
        SocialEndpoints.posts.postImage + post.id
      );
      if (response.status === 200) {
        const url = window.URL.createObjectURL(response.data);
        setPostPicture(url);
      }
    };

    if (post.file_name) {
      getImage();
    }
  }, [post]);

  const getStatusText = (status) => {
    switch (status) {
      case "published":
        return (
          <Button className="btn btn-success">
            {t("Backend.Post.State.PublishedAt")} <b> {post.posted_at} </b>
          </Button>
        );
      case "draft":
        return (
          <Button className="btn btn-info">
            {t("Backend.Post.State.Draft")}
          </Button>
        );
      case "error":
        return (
          <Button className="btn btn-danger">
            {t("Backend.Post.State.Error")}
          </Button>
        );
      case "ready":
        return (
          <Button className="btn btn-warning">
            {t("Backend.Post.State.Ready")} <b> {post.scheduled_for} </b>
          </Button>
        );

      default:
        return (
          <Button className="btn btn-info">
            {t("Backend.Post.State.Draft")}
          </Button>
        );
    }
  };

  return (
    <div className="timeline-item">
      <div className="timeline-block">
        <div className="timeline-box card">
          <div className="card-body">
            <div style={timelineContainerStyle}>
              <div style={timelineItemStyle}>
                <div style={timelineDateStyle}>
                  {getStatusText(post.post_status)}
                </div>
                <div style={timelineContentStyle}>
                  <CompleteDropdown
                    deleteAction={deleteAction}
                    editAction={editAction}
                  >
                    <div className="dropdown-divider" />
                    <Dropdown.Item
                      onClick={() => {
                        publish(post.id);
                      }}
                      className="text"
                    >
                      <i className="pli-trash fs-5 me-2" />
                      <FontAwesomeIcon icon={faPaperPlane} />{" "}
                      {t("Backend.Post.Publish")}
                    </Dropdown.Item>
                  </CompleteDropdown>
                </div>
              </div>
            </div>

            <h5 className="mt-3 font-size-16"> {post.title}</h5>
            <div className="text-muted">
              <p className="mb-0">{post.text}</p>
            </div>
            {postPicture && (
              <div class="timeline-album">
                <img src={postPicture} alt="postimage" style={image} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Timelineitem;
