import { useEffect, useState } from "react";
import generateRandomString from "@serie3/mymediaplanner/Domain/generateRandom";
import { Link, useSearchParams } from "react-router-dom";
import getLinkedinSettings from "@serie3/mymediaplanner/Config/Social/linkedinSettings";
import crudApi from "@serie3/common/api/crudApi";
import LinkeindEndpoints from "@serie3/mymediaplanner/Modules/Social/Linkedin/Resources/endpoints";
import SocialEndpoints from "@serie3/mymediaplanner/Modules/Social/endpoints";
import { Button, Row, Col, Card } from "react-bootstrap";
import ToastMessages from "../../ToastMessages";

const Callbacks = () => {
  const linkedinSettings = getLinkedinSettings();
  const [authUrl, setAuthUrl] = useState("");
  const [searchParams] = useSearchParams();
  const [connectedPlatforms, setConnectedPlatforms] = useState([]);
  const availablePlatforms = ["linkedin"];

  const disconnect = async () => {
    let response = await crudApi.justGet(LinkeindEndpoints.disconnect);
    response.ok ? ToastMessages.SuccessfulSave() : ToastMessages.Error();
  };

  const createLinkedinAuthLink = (statusCode = "") => {
    return (
      linkedinSettings.linkeindAuthApiUrl +
      "?response_type=" +
      linkedinSettings.response_type +
      "&state=" +
      statusCode +
      "&client_id=" +
      linkedinSettings.client_id +
      "&redirect_uri=" +
      linkedinSettings.redirect_uri +
      "&scope=" +
      linkedinSettings.scope
    );
  };

  useEffect(() => {
    const getConnectedPlatforms = async () => {
      let connectedPlatforms = await crudApi.getAll(SocialEndpoints.overview);
      console.log(connectedPlatforms);
      if (connectedPlatforms.ok) {
        setConnectedPlatforms([...connectedPlatforms.data["knownTokens"]]);
      }
    };

    let statusCode = localStorage.getItem("code");
    let code = searchParams.get("code");
    let linkedinState = searchParams.get("state");

    if (code && linkedinState) {
      if (statusCode === linkedinState) {
        let data = {
          code: code,
        };
        crudApi.createNew(LinkeindEndpoints.connect, data);
      }
    } else {
      if (!statusCode) {
        statusCode = generateRandomString();
        localStorage.setItem("code", statusCode);
      }
      let authUrl = createLinkedinAuthLink(statusCode);
      setAuthUrl(authUrl);
    }

    getConnectedPlatforms();
  }, []);

  const ConnectButton = ({ platformName }) => {
    return (
      <Link className="btn btn-primary" to={authUrl}>
        Connect to {platformName}
      </Link>
    );
  };

  const DisconnectButton = ({ platformName }) => {
    return (
      <Button
        className="btn btn-danger"
        onClick={() => {
          disconnect();
        }}
      >
        Disconnect from {platformName}
      </Button>
    );
  };

  return (
    <Row>
      <Col>
        <h4>Available platforms</h4>
        <Card>
          <Card.Body>
            {availablePlatforms.map((platform) => {
              console.log(connectedPlatforms);
              const isConnected = connectedPlatforms.some(
                (token) => token.plattform_name === platform.toLowerCase()
              );

              return (
                <Card>
                  <Card.Header>{platform}</Card.Header>
                  <Card.Body>
                    <div key={platform}>
                      <h3>{platform}</h3>
                      {isConnected ? (
                        <DisconnectButton platformName={platform} />
                      ) : (
                        <ConnectButton platformName={platform} />
                      )}
                    </div>
                  </Card.Body>
                </Card>
              );
            })}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default Callbacks;
