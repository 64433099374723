import { Outlet } from "react-router-dom";
import { Container } from "react-bootstrap";
import Sidebar from "./Sidebar";

const BackendLayout = () => {
  return (
    <>
      <Sidebar />
      <div className="main-content">
        <div className="page-content">
          <Container fluid>
            <Outlet />
          </Container>
        </div>
      </div>
    </>
  );
};

export default BackendLayout;
