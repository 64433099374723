import PostForm from "@serie3/mymediaplanner/Modules/Social/Forms/PostForm";
import { useFormik } from "formik";
import { Button, Col, Row } from "react-bootstrap";
import TextField from "../../Form/TextField";
import TextArea from "../../Form/TextArea";
import DateTimeField from "../../Form/DateTimeField";
import { useTranslation } from "react-i18next";
import NewModal from "../../shared/Modals/NewModal";
import SaveButton from "../../shared/Buttons/SaveButton";
import Post from "@serie3/mymediaplanner/Modules/Social/Entity/Post";
import FileDropzone from "../../shared/Modals/FileDropzone";
import { useState, useEffect } from "react";
import SocialEndpoints from "@serie3/mymediaplanner/Modules/Social/endpoints";
import crudApi from "@serie3/common/api/crudApi";

const PostFormView = ({
  showEdit,
  setShowEdit,
  editData,
  dataList,
  setDataList,
  setEditData,
  trigger,
  setTrigger,
}) => {
  const editWorkexperienceForm = PostForm.createPostForm(
    dataList,
    setDataList,
    setShowEdit
  );

  const { t } = useTranslation();
  const [postPicture, setPostPicture] = useState(null);
  const [showFileUpload, setShowFileUpload] = useState(false);
  const [triggerRerenderProfile, setTriggerRerenderProfile] = useState(-1);

  useEffect(() => {
    const getImage = async () => {
      const response = await crudApi.getBinary(
        SocialEndpoints.posts.postImage + editData.id
      );
      if (response.status === 200) {
        const url = window.URL.createObjectURL(response.data);
        setPostPicture(url);
      }
    };

    if (editData && editData.file_name) {
      getImage();
    }
  }, [editData, triggerRerenderProfile]);

  const formik = useFormik(editWorkexperienceForm);
  const Save = () => {
    return (
      <>
        <Button
          onClick={() => {alert('FIREEEEE')}}
        >{t("Backend.Post.Publish")}</Button>
        <SaveButton
          submitFunction={(e) => {
            formik.handleSubmit(e);
            e.preventDefault();
          }}
        />
      </>
    );
  };

  const setData = (data) => {
    setEditData(data[0]);
    let renderValue = triggerRerenderProfile * -1;
    setTriggerRerenderProfile(renderValue);
  };

  let isEdit = editData ? true : false;
  let additionalData = editData ? { id: editData.id } : {};

  return (
    <>
      <NewModal show={showFileUpload} setShow={setShowFileUpload}>
        <FileDropzone
          uploadUrl={SocialEndpoints.posts.edit}
          setName={false}
          setData={setData}
          edit={isEdit}
          additionalData={additionalData}
        />
      </NewModal>
      <NewModal
        show={showEdit}
        setShow={setShowEdit}
        title={"titel"}
        ExtraButton={Save}
        fullscreen={true}
        onShow={() => {
          editData
            ? formik.setValues(PostForm.setEditData(editData))
            : formik.setValues(Post);
        }}
        onExit={() => {
          let triggervalue = trigger * -1;
          setTrigger(triggervalue);
          formik.resetForm({ values: { Post } });
          setPostPicture(null);
        }}
      >
        <Row>
          <Col md={8}>
            <h3>Edit</h3>
            <Row>
              <Col>
                <TextField
                  formik={formik}
                  label={t("Backend.Post.Title")}
                  valueName={"title"}
                  style={{ marginBottom: "10px" }}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <DateTimeField
                  formik={formik}
                  label={t("Backend.Post.Schedule")}
                  valueName={"scheduled_for"}
                  style={{ marginBottom: "10px" }}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <TextArea
                  formik={formik}
                  label={t("Backend.Post.Text")}
                  valueName={"text"}
                  style={{ marginBottom: "10px" }}
                  rows={10}
                />
              </Col>
            </Row>
          </Col>
          <Col>
            <h3>{t("Backend.Post.Preview")}</h3>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            {postPicture && (
              <div class="timeline-album">
                <img
                  src={postPicture}
                  alt="postimage"
                  style={{ maxWidth: "100%" }}
                />
              </div>
            )}
          </Col>
          <Col>
            <div
              className="btn btn-primary"
              onClick={() => setShowFileUpload(true)}
            >
              {postPicture
                ? t("Backend.Post.ChangeImage")
                : t("Backend.Post.UploadImage")}
            </div>
          </Col>
        </Row>
      </NewModal>
    </>
  );
};

export default PostFormView;
