import Post from "../Entity/Post";
import * as Yup from "yup";
import CrudApi from "@serie3/common/api/crudApi";
import SocialEndpoints from "../endpoints";
import listHandler from "@serie3/common/Domain/listHandler";

const createPostForm = (dataList, setDataFunction, setAdditionalData) => {
  const validationSchema = Yup.object({
    Tipp: Yup.string().required("Required"),
  });

  const onSubmit = async (values) => {
    const originalValues = { ...values };
    if (values.id > 0) {
      let payload = JSON.stringify(values, null, 2);
      const response = await CrudApi.edit(
        SocialEndpoints.posts.edit,
        payload
      );

      let originalPayload = JSON.stringify(originalValues, null, 2);
      listHandler.updateList(
        response,
        originalPayload,
        dataList,
        setDataFunction
      );
    } else {
      let payload = JSON.stringify(values, null, 2);
      const response = await CrudApi.createNew(
        SocialEndpoints.posts.new,
        payload
      );
      listHandler.newToList(
        response,
        dataList,
        setDataFunction,
        null
      );

      if(response.ok){
        values.id = response.data.id;
        let additionalData = {
          id: response.data.id,
        }
        setAdditionalData(additionalData);
      }
    }
  };

  return {
    initialValues: Post,
    onSubmit: (values, actions) => {
      onSubmit(values);
    },
  };
};

const setEditData = (editData) => {
  let Post = {
    id: editData.id,
    scheduled_for: editData.scheduled_for,
    title: editData.title,
    text: editData.text,
    portal: editData.portal,
    draft: editData.draft    
  };

  return Post;
};

export default { createPostForm, setEditData };
